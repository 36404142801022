<template>
   <div class="baseRegisterRow" :style="{height: gl.regRowHt+'px'}">
    <button class=" registerHeaders"
    :class="{rightJustify: title.rjs}"
      v-for=" (title, idx)  in titles" :key="idx">
        {{ title.tx }}
    </button>
   </div> 
</template>

<script setup>
import {gl} from "@/Store"
 const titles = [
    {tx:"Date", rjs: false},
    {tx:"Payee", rjs: false},
    {tx:"Category", rjs: false},
    {tx:"Memo", rjs: false},
    {tx:"Payment", rjs: true},
    {tx:"Deposit", rjs: true},
    {tx:"Balance", rjs: true},
  ];

</script>

<style scoped>
.registerHeaders{
  box-sizing: border-box;
  text-align: left;
  vertical-align: middle;
  overflow: hidden;
  white-space: nowrap;
  border: 1px solid black;
}

.rightJustify{
  text-align: right;
}
</style>
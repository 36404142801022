<template>
  <div class="bar">
    <button class="pageButton" @:mousedown="mousePage('PageUp')">⇑</button>
    <div
      class="trackAndThumb"
      ref="track"
      v-on:mousemove="mouseMove"
      v-on:mouseup="mouseUp"
      @mouseleave="mouseLeave"
    >
      <div class="thumb" v-on:mousedown="mouseDown" ref="thumb"></div>
    </div>
    <button class="pageButton" @:mousedown="mousePage('PageDown')">⇓</button>
  </div>
</template>

<script setup>
//console.log("scrollBar")
import { ref, onMounted, onBeforeUnmount, defineProps, watch } from "vue";
import { btmRowStore, regHtStore, gl } from "@/Store";
const props = defineProps({
  totalRows: Number,
});

const track = ref(null);
const thumb = ref(null);

let trackHeight = 0;
let thumbHeight = 0;
const thumbSize = ref("10px");
const vPos = ref("0px");
let mouseDn = false;

watch(regHtStore, () => {
  setTrackParams();
  setThumbSize();
  gl.pageRows = Math.trunc(regHtStore.registerHeight / gl.regRowHt);
  setSliceParms(btmRowStore.bottomRow);
});

const mousePage = (dir) => page(dir);
const mouseDown = (e) => (mouseDn = true);
const mouseUp = (e) => (mouseDn = false);
const mouseLeave = (e) => {
  if (mouseDn) mouseUp();
};

function mouseMove(e) {
  if (mouseDn) {
    let ratio = (e.clientY - 88) / (trackHeight - thumbHeight);
    let bottomRow = props.totalRows * ratio;
    setSliceParms(bottomRow);
  }
}

function setThumbPosition(bottomRow) {
  let ratio = bottomRow / props.totalRows;
  ratio = (bottomRow - gl.pageRows * (1 - ratio)) / props.totalRows;
  let tp = (trackHeight - thumbHeight) * ratio + thumbHeight / 2;
  if (tp < 0) tp = 0;
  vPos.value = tp - thumbHeight / 2 + "px";
}

function page(code) {
  let endRow = 0;
  switch (code) {
    case "PageUp":
      endRow = btmRowStore.bottomRow - gl.pageRows;
      break;
    case "PageDown":
      endRow = btmRowStore.bottomRow + gl.pageRows;
      break;
    case "ArrowUp":
      endRow = btmRowStore.bottomRow + -1;
      break;
    case "ArrowDown":
      endRow = btmRowStore.bottomRow + +1;
      break;
    default:
  }
  setSliceParms(endRow);
}

function setSliceParms(endRow) {
  endRow = endRow > gl.totalRows ? gl.totalRows : endRow;
  let startRow = endRow - gl.pageRows;
  if (startRow < 0) {
    startRow = 0;
    endRow = startRow + gl.pageRows;
  }
  if (btmRowStore.bottomRow != endRow || btmRowStore.topRow != startRow) {
    btmRowStore.topRow = startRow;
    btmRowStore.bottomRow = endRow;
    setThumbPosition(btmRowStore.bottomRow);
  }
}

const handleKeypress = (e) => page(e.code);

const setThumbSize = () => {
  thumbHeight = trackHeight / (props.totalRows / gl.pageRows);
  if (thumbHeight >= trackHeight) thumbHeight = 0;
  else if (thumbHeight < 10) thumbHeight = 20;
  thumbSize.value = thumbHeight + "px";
  vPos.value = trackHeight - thumbHeight + "px";
};

const setTrackParams = () => {
  trackHeight = track.value.offsetHeight;
};

onMounted(() => {
  window.addEventListener("keydown", handleKeypress);
  setTrackParams();
  setThumbSize();
});
onBeforeUnmount(() => window.removeEventListener("keydown", handleKeypress));
</script>

<style scoped>
.bar {
  background-color: wheat;
  border: 1px solid #333;
  border-radius: 6px;
  display: grid;
  height: 100%;
  grid-template-rows: 20px 1fr 20px;
  grid-column-start: 2;
}
.trackAndThumb {
  display: block;
  box-sizing: border-box;
  background-color: lightgrey;
  height: 100%;
  position: relative;
  width: 90%;
}

.thumb {
  border-radius: 6px;
  background-color: lightslategray;
  position: absolute;
  top: v-bind(vPos);
  left: 0;
  width: 100%;
  height: v-bind(thumbSize);
}

.pageButton {
  background-color: darkgray;
  width: 100%;
  height: 20px;
}
</style>

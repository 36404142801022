<template>
  <div class="tabs" >
    <button @click="tabClicked(-1)" class="tab">Totals</button>
    <button
      @click="tabClicked(idx)"
      class="tab"
      v-for="(accdesc, idx) in accounts"
      :key="idx"
    >
      {{ accdesc.accdesc }}
  </button>
</div>
</template>

<script setup>
import { accounts } from "../Database.js";
import { store } from "@/Store";

let currentTab = -1;
function tabClicked(tab) {
  if (tab != currentTab){
    currentTab = tab;
    if (tab < 0)
     store.displayAcc = -1;
    else {
      store.registerDirty += 1
      store.displayAcc = accounts[tab].accnum;
    }
   }
}
</script>

<style scoped>
.tabs {
  background-color: lightgrey;
  border: 1px solid;
  box-sizing: border-box;
  display: grid;
  height: 100%;
  grid-template-columns: repeat(9, 1fr);
  grid-column-start: 1;
  grid-row-start: 2;
}
.tab {
  box-sizing: border-box;
  overflow: hidden;
  border: 1px solid;
  padding: 0;
  height: 100%;
  width: 100%;
  grid-row-start: 1;
}
</style>

//import { ref,} from "vue";
let accounts = [];
let names = [];
let categories = [];

//const baseRL = "HTTP://localhost:5000/";
const baseRL = "HTTPS://dan-g.lol/db/";
export { accounts, names, categories };

export async function getAccountData() {
  console.log("getAccountData");
  const response = await fetch(baseRL + "accounts");
  accounts = await response.json();
}

export async function getNamesData() {
  //console.log("getNamesData");
  const response = await fetch(baseRL + "names");
  names = await response.json();
}
export async function getCategoriesData() {
  //console.log("getCategoriesData");
  const response = await fetch(baseRL + "categories");
  categories = await response.json();
}

export async function getRegisterData(acc) {
  const url = baseRL + "register?accnum="+acc;
  const response = await fetch(url);
  const register = await response.json();
  //console.log("register")
  return register
}

export async function getTotalsData() {
  const response = await fetch(baseRL + "balances");
  const balances = await response.json();
  return balances
}

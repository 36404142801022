<template>
  <div class="container">
    <ScrollBar :totalRows="totalRows"></ScrollBar>
    <div id="regTable">
      <RegHeader></RegHeader>
      <div
        class="baseRegisterRow"
        :style="{ height: gl.regRowHt + 'px' }"
        :class="{ ltf: idx === sliceRowGtToday }"
        @click="rowClicked(idx)"
        @dblclick="rowDoubleClicked(idx)"
        v-for="(row, idx) in regData.x"
        :key="row._id"
        :ref="(el) => (rows[idx] = el)"
      >
        <RegCell :col="1" :value="row.datex"></RegCell>
        <RegCell :col="2" :value="row.nameky" ></RegCell>
        <RegCell :col="3" :value="row.category"></RegCell>
        <RegCell :col="4" :value="row.memo" ></RegCell>
        <RegCell :col="5" :value="row.amt"></RegCell>
        <RegCell :col="6" :value="row.amt"></RegCell>
        <RegCell :col="7" :value="row.bal"></RegCell>
        <RegCell :col="8" :value="row._id" ></RegCell>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  watch,
  onMounted,
  onBeforeUpdate,
  onUpdated,
  ref,
  unref,
  reactive,
} from "vue";
import RegCell from "./RegCell.vue";
import ScrollBar from "./ScrollBar.vue";
import { getRegisterData } from "@/Database";
import RegHeader from "./RegHeader.vue";
import { store, regHtStore, btmRowStore, gl } from "@/Store";
const registerData = await getRegisterData(store.displayAcc);
const totalRows = registerData.length;
gl.totalRows = totalRows;
let regHt = regHtStore.registerHeight;
let regData = reactive({ x: [] });
gl.pageRows = Math.trunc(regHt / gl.regRowHt);
btmRowStore.bottomRow = totalRows;
btmRowStore.topRow = totalRows - gl.pageRows;
regData.x = registerData.slice(totalRows - gl.pageRows, totalRows);
const rows = ref([]);
const cell = ref([]);
let firstRowGreaterThanToday = -1;
let sliceRowGtToday = -1;
let selectedRow = -1;

//const today = Date.now()
const today = 1706162400000;

findRegisterRowGtToday();
findSliceRowGtToday();

function rowDoubleClicked(row){
  console.log("base = " + registerData[row+btmRowStore.topRow]._id);
}

function rowClicked(row) {
  //:ref="(el) => (rows[idx] = el)"
  //console.log(rows.value[row]);
  //console.log("selectedRow = " + selectedRow);
  //console.log("rowClicked = " + row);
  //console.log("slice = " + regData.x[row]._id);
  //console.log("base = " + registerData[row+btmRowStore.topRow]._id);
  if (selectedRow != row && selectedRow >= 0)
    rows.value[selectedRow].style.backgroundColor = "white";
  rows.value[row].style.backgroundColor = "#b0c8cf";
  selectedRow = row;
  //console.log(...rows.value);
  //console.log("idClicked = " + cell.value[row].value);
}

//Find first row greater than today
function findRegisterRowGtToday() {
  for (let i = 0; i < registerData.length; i++) {
    if (registerData[i].datex > today) {
      firstRowGreaterThanToday = i - 1;
      break;
    }
  }
}

// find the row slice row that contains the greater than today row
function findSliceRowGtToday() {
  sliceRowGtToday = firstRowGreaterThanToday - btmRowStore.topRow;
}

watch(btmRowStore, () => {
  regData.x = registerData.slice(btmRowStore.topRow, btmRowStore.bottomRow);
  findSliceRowGtToday();
  if (selectedRow >= 0){
    rows.value[selectedRow].style.backgroundColor = "#b0c8cf";
  selectedRow = -1
}
});

//onUpdated(() => {console.log(...rows.value);})

onMounted(() => {
  //console.log(rows.value[0].width)
  //console.log(unref(cell))
  // findTodayRow()
});
</script>

<style>
.container {
  display: grid;
  border: 1px solid black;
  grid-template-columns: 1fr 20px;
}
#regTable {
  display: grid;
  box-sizing: border-box;
  align-content: start;
  overflow: hidden;
  top: 0;
  width: 100%;
  height: 100%;
  grid-row-start: 1;
  grid-column-start: 1;
}
.baseRegisterRow {
  display: grid;
  width: 100%;
  background-color: white;
  box-sizing: border-box;
  grid-template-columns:90px 200px 200px 1fr 90px 90px 90px 0;
  :hover {
    background-color: #dbeaf4;
    cursor: pointer;
    overflow: visible;
  }
}
.ltf {
  border-bottom: 2px solid black;
}
</style>


<template>
  <div :class="{ rightJustify: justifyRight }" class="regCell">{{ st }}</div>
</template>

<script setup>
//console.log("RegCell")
import { names, categories } from "@/Database";
import { defineProps, toRef, defineExpose } from "vue";

const props = defineProps({
  col: Number,
  value: null,
});
defineExpose(props)
let st = "";
let justifyRight = false;

function getFormattedDate(value) {
  let dt = new Date(value);
  let year = dt.getFullYear();
  let month = (1 + dt.getMonth()).toString().padStart(2, "0");
  let day = dt.getDate().toString().padStart(2, "0");
  return month + "/" + day + "/" + year;
}

function getFormattedDollars(num, includeSign) {
  let neg = false;
  if (num < 0) {
    neg = true;
    num = -num;
  }
  let inputString = num.toString();
  let len = inputString.length;
  if (len === 1) {
    inputString = "0" + inputString;
    len = 2;
  }
  let formattedString = "";

  for (let i = len - 1; i >= 0; i--) {
    formattedString = inputString[i] + formattedString;
    if (i === len - 2) formattedString = "." + formattedString;
    else if (i === len - 5 && len > 5) formattedString = "," + formattedString;
  }
  if (includeSign && neg) formattedString = "(" + formattedString + ")";
  return formattedString;
}

//   console.log(props.col);
//   console.log(props.value);
switch (props.col) {
  case 1:
    st = getFormattedDate(props.value);
    break;
  case 2:
    st = names[names.findIndex((element) => element._id === props.value)].namex;
    break;
  case 3:
    st =
      categories[categories.findIndex((element) => element._id === props.value)]
        .description;
    break;
  case 4: //memo
    st = toRef(props.value);
    break;
  case 5:
    if (props.value < 0) st = getFormattedDollars(props.value);
    justifyRight = true;
    break;
  case 6:
    if (props.value > 0) st = getFormattedDollars(props.value);
    justifyRight = true;
    break;
  case 7:
    st = getFormattedDollars(props.value);
    justifyRight = true;
    break;
    case 8:
    st = toRef(props.value);
    break;
  default:
    st = "Error";
}
</script>

<style scoped>
.rightJustify {
  text-align: right !important;
}

.regCell {
  box-sizing: border-box;
  text-align: left;
  vertical-align: middle;
  overflow: hidden;
  white-space: nowrap;
  border: 1px solid black;
}
</style>

<template>
    <div class="footer">This is the footer.</div>
</template>

<style scoped>
.footer{
    box-sizing: border-box;
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
  white-space: nowrap;
  border: 1px solid black;
  grid-column-start: 1;
  grid-column-end: -1;
  grid-row-start: 4;
}
</style>
<template>
  <div class="totalsPage" ref="page">
    <div class="totalsRow" v-for="(formattedBalData, desc) in formattedBalData" :key="desc">
      <div class="totalsCol">{{ formattedBalData.desc }}</div>
      <div class="totalsCol">{{ formattedBalData.cBal }}</div>
      <div class="totalsCol">{{ formattedBalData.fBal }}</div>
    </div>
  </div>
</template>

<script setup>
import { getTotalsData, accounts } from "@/Database";
import { onBeforeUnmount, onMounted, ref } from "vue";
const page = ref(null);
//console.log("MainTotals");
let formattedBalData = [];
const balanceData = await getTotalsData();
accounts.forEach((value, i) =>
  formattedBalData.push({
    desc: value.accdesc,
    cBal: balanceData[i].amt,
    fBal: balanceData[i + accounts.length].amt,
  })
);

</script>

<style scoped>
.totalsPage {
  display: grid;
  border: 2px solid black;
  align-content: start;
  
  grid-template-rows: 30px;
  grid-row-start: 3;
  grid-column-start: 1;
}

.totalsRow {
  display: grid;
  width: 100%;
  box-sizing: border-box;
  height: 28px;
  grid-template-columns: 300px 150px 150px;
}

.totalsCol {
  border: 1px solid;
  padding: 1px;
  height: 100%;
  width: 100%;
  grid-row-start: 1;
}
</style>

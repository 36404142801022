<template>
    <div >Holding</div>
</template>

<script setup>
//console.log("SetupData")
import { store } from '@/Store';
import { getAccountData, getNamesData, getCategoriesData } from '@/Database';
await getAccountData()
await getNamesData()
await getCategoriesData()

store.setupLoading = false
</script>
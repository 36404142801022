import { reactive, provide } from "vue";

export const store = reactive({
  displayAcc: -1,
  setupLoading: true,
  registerDirty: 0,
});

//Need seperate store object for watchs
//or the watch watches everything in the object

export const regHtStore = reactive({
  registerHeight: 0,
});
export const btmRowStore = reactive({
  bottomRow: 0,
  topRow: 0,
});

//Global variables not reactive
export const gl = {
  regRowHt: 28,
  pageRows: 11,
  totalRows: 0,
};

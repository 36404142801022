// :key forces the componet to be rerun
<template>
  <Suspense>
    <div v-if="store.setupLoading">
      <SetupData> </SetupData> >
    </div>

    <div class="app" :style="{ 'grid-template-rows':  appRows  }" v-else>
      <MainHeader></MainHeader>
      <RegTabs></RegTabs>
      <MainTotals v-if="store.displayAcc < 0 "></MainTotals>
      <RegTable v-else :acc="store.displayAcc" :key="store.registerDirty"></RegTable>
      <MainFooter></MainFooter>
    </div>
  </Suspense>
</template>

<script setup>
import { version, onMounted, onBeforeUnmount, watchEffect , ref } from "vue";
console.log("Vue Version =" + version);
import MainHeader from "./components/MainHeader.vue";
import RegTabs from "./components/RegTabs.vue";
import SetupData from "./components/SetupData.vue";
import RegTable from "./components/RegTable.vue";
import MainFooter from "./components/MainFooter.vue";
import MainTotals from "./components/MainTotals.vue";
import {store, regHtStore, gl } from "./Store";

onMounted(() => {
  window.addEventListener("resize", handleWindowSizeChange);
  //console.log("Window Height = " + window.innerHeight)
  handleWindowSizeChange();
});
onBeforeUnmount(() => {
  window.removeEventListener("resize", handleWindowSizeChange);
});
const handleWindowSizeChange = () => {
  regHtStore.registerHeight = window.innerHeight-heightAdjust
  //console.log("Window Height = " + window.innerHeight)
};
const headerHeight = 30
const footerHeight = 30
const tabsHeighht  = 34
const heightAdjust = headerHeight+footerHeight+tabsHeighht+gl.regRowHt+6
const appRows = `${headerHeight}px ${tabsHeighht}px 1fr ${footerHeight}px`
</script>

<style>
#app{
  display: block;
  margin: 0;
  padding: 0;
}

.app {
  display: grid;
  box-sizing: border-box;
  position: fixed;
  padding: 2px;
  width: 100%;
  height: 100%;

}
</style>
